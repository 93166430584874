import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ProgressContainerComponent } from './components/progress-container/progress-container.component';
import { NoContentComponent } from './components/no-content/no-content.component';
import { ConfirmationModalComponent } from './components/modal/confirmation-modal/confirmation-modal.component';
import { MobileAwareComponent } from './components/mobile-aware/mobile-aware.component';
import { LinkComponent } from './components/link/link.component';
import { ButtonComponent } from './components/button/button.component';
import { ItemSelectComponent } from './components/item-select/item-select.component';
import { ErrorDetailsComponent, NotificationDetailsComponent } from './helper/notification.handler';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { ProgressService } from './service/progress.service';
import { DynamicOverlay } from './components/overlay/dynamic-overlay.service';
import { DynamicOverlayContainer } from './components/overlay/dynamic-overlay-container';
import { MenuListItemComponent } from '@common/components/menu-list-item/menu-list-item.component';
import { CenterContentHolderComponent } from '@common/components/center-content-holder/center-content-holder.component';
import { AvatarComponent } from '@common/components/avatar/avatar.component';
import { PermissionCheckerComponent } from '@common/components/permission-checker/permission-checker.component';
import { DragDropDirective } from '@common/components/file-upload/drag-drop.directive';
import { FileUploadComponent } from '@common/components/file-upload/file-upload.component';
import { ImageCropperComponent } from '@common/components/image-cropper/image-cropper.component';
import { RetryableImageComponent } from '@common/components/retryable-image/retryable-image.component';
import { ReplaceAllPipe } from '@common/pipes/replace.pipe';
import { ItemsMultiSelectComponent } from '@common/components/items-multi-select/items-multi-select.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { PluralizePipe } from './pipes';
import { PageComponent } from './components/page/page.component';

const COMMON_COMPONENTS = [
  PageComponent,
  PageNotFoundComponent,

  NotificationDetailsComponent,
  ErrorDetailsComponent,

  ProgressContainerComponent,
  NoContentComponent,
  CenterContentHolderComponent,
  ConfirmationModalComponent,

  DragDropDirective,
  FileUploadComponent,

  LinkComponent,
  ButtonComponent,
  ItemSelectComponent,
  ItemsMultiSelectComponent,
  LinkComponent,
  FormInputComponent,
  FormSelectComponent,
  MenuListItemComponent,
  PermissionCheckerComponent,
  RetryableImageComponent,

  AvatarComponent,

  MobileAwareComponent,
];

const PIPES = [
  ReplaceAllPipe
];

const COMMON_MODULES = [
  CommonModule,

  RouterModule,
  FormsModule,
  ReactiveFormsModule,

  // pipes modules
  PluralizePipe,

  ImageCropperComponent,
];

const COMMON_MATERIAL_MODULES = [
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatTabsModule,
];

@NgModule({
  declarations: [
    COMMON_COMPONENTS,
    PIPES,
  ],
  imports: [
    ...COMMON_MODULES,
    ...COMMON_MATERIAL_MODULES,
    MatChipsModule,
  ],
  exports: [
    COMMON_MODULES,
    COMMON_MATERIAL_MODULES,
    COMMON_COMPONENTS,
    PIPES,
  ],
  providers: [
    ProgressService,
    DynamicOverlay,
    DynamicOverlayContainer,
    PIPES,
  ],
})
export class CommonComponentsModule {
}
